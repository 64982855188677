// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className='footer'>
            <p>© 2024 Poo Deng. 💩 All poops reserved. Poo responsibly!</p>
        </footer>
    );
};

export default Footer;
