import React, { useState, useRef } from 'react';
import './Hero.css';
import VideoPlayer from './VideoPlayer';
import { motion } from 'framer-motion';

const Hero = () => {
    const [poops, setPoops] = useState([]);
    const timerRef = useRef(null);

    const handlePoopClick = () => {
        // First clear the existing poops
        setPoops([]);

        // Add a slight delay to ensure poops are cleared before adding new ones
        setTimeout(() => {
            const newPoops = Array.from({ length: 30 }).map(() => {
                const x = Math.random() * (window.innerWidth - 50); // Random X position
                const y = Math.random() * (window.innerHeight + window.scrollY); // Random starting Y position (top 30% of the screen)
                const rotation = Math.random() * 360; // Random rotation
                const scale = Math.random() * 1.5 + 0.5; // Random scale between 0.5 and 2
                return { x, y, rotation, scale };
            });

            // Now add new poops after the state has been cleared
            setPoops(newPoops);
        }, 10); // Short delay to ensure state is updated

        // Reset the timer on each click
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        // Remove poop explosion after 3 seconds
        timerRef.current = setTimeout(() => {
            setPoops([]);
        }, 3000);
    };

    return (
        <div className='hero-container'>
            {/* Animate the title to slide in from the top */}
            <motion.h1
                initial={{ y: -100, opacity: 0 }} // Start from above and invisible
                animate={{ y: 0, opacity: 1 }} // Move to the final position and become visible
                transition={{ duration: 1 }} // Duration of the animation
            >
                Welcome to Poo Deng
            </motion.h1>
            {/* Animate the video to fade in */}
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }}>
                <VideoPlayer />
            </motion.div>
            {/* Animate the button to fade and scale in */}
            <motion.button
                onClick={handlePoopClick}
                className='poop-button'
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }} // Delay so it animates after the title
            >
                Make It Poo!
            </motion.button>

            {/* Poop explosion effect */}
            <div className='poop-explosion'>
                {poops.map((poop, index) => (
                    <div
                        key={index}
                        className='poop'
                        style={{
                            left: `${poop.x}px`, // X position from JavaScript
                            top: `${poop.y}px`, // Y position from JavaScript (starting point)
                            transform: `rotate(${poop.rotation}deg) scale(${poop.scale})`,
                        }}
                    >
                        💩
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Hero;
