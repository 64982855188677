// src/components/Header.js
import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <header className='header'>
            <nav>
                <ul>
                    <li>
                        <a href='https://x.com/PooDengSol' target='_blank' rel='noopener noreferrer'>
                            <img src={`${process.env.PUBLIC_URL}/x.png`} alt='X Logo' />
                        </a>
                    </li>
                    <li>
                        <a
                            href='https://pump.fun/5Ek6tcihqPyBSscedhCeC8Zs3cXEzyDDtaRoB9RJpump'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img src={`${process.env.PUBLIC_URL}/pumpfun.jpg`} alt='PumpFun' />
                        </a>
                    </li>
                    <li>
                        <a
                            href='https://www.dextools.io/app/en/solana/pair-explorer/5Ek6tcihqPyBSscedhCeC8Zs3cXEzyDDtaRoB9RJpump'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img src={`${process.env.PUBLIC_URL}/dexscreener.png`} alt='DexScreener' />
                        </a>
                    </li>
                    <li>
                        <a href='https://t.me/+tO-f6kKTlXU3OWYx' target='_blank' rel='noopener noreferrer'>
                            <img src={`${process.env.PUBLIC_URL}/telegram.png`} alt='Telegram' />
                        </a>
                    </li>
                    <li>
                        <a
                            href='https://www.dextools.io/app/en/solana/pair-explorer/5Ek6tcihqPyBSscedhCeC8Zs3cXEzyDDtaRoB9RJpump'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img src={`${process.env.PUBLIC_URL}/dextools.png`} alt='DexTools' />
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
