import React, { useState } from 'react';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Header from './components/Header';
import Features from './components/Features';
import './App.css';

function App() {
    const [poops, setPoops] = useState([]);

    // Handle the click or touch event and create new poop emoji at the click/touch position
    const handleClick = e => {
        let x, y;

        // Handle touch event on mobile
        if (e.type === 'touchstart') {
            x = e.touches[0].clientX; // Get X position from the first touch point
            y = e.touches[0].clientY + window.scrollY; // Get Y position from the first touch point
        } else {
            // Handle click event on desktop
            x = e.clientX;
            y = e.clientY + window.scrollY;
        }

        const newPoop = {
            x,
            y,
            id: Date.now(),
        };

        setPoops(prevPoops => [...prevPoops, newPoop]);

        // Remove poop emoji after 5 seconds
        setTimeout(() => {
            setPoops(prevPoops => prevPoops.filter(poop => poop.id !== newPoop.id));
        }, 5000);
    };

    return (
        <div className='App' onClick={handleClick} onTouchStart={handleClick}>
            <Header />
            <Hero />
            {/* <About /> */}
            <Features />
            <Footer />
            {/* Render the falling poop emojis */}
            <div class='poop-wrapper'>
                {poops.map(poop => (
                    <div key={poop.id} className='poop-emoji' style={{ top: `${poop.y}px`, left: `${poop.x}px` }}>
                        💩
                    </div>
                ))}
            </div>
        </div>
    );
}

export default App;
