// src/components/Features.js
import React from 'react';
import './Features.css';

const Features = () => {
    return (
        <div className='features-container'>
            <h2>CA: 5Ek6tcihqPyBSscedhCeC8Zs3cXEzyDDtaRoB9RJpump</h2>
            <ul>
                <li>Eco-Friendly Poop: 100% biodegradable!</li>
            </ul>
        </div>
    );
};

export default Features;

// // src/components/Features.js
// import React from 'react';
// import './Features.css';

// const Features = () => {
//   return (
//     <div className="features-container">
//       <h2>Why You’ll Love Poo Deng</h2>
//       <ul>
//         <li>100% Organic Poop Drops 🌿</li>
//         <li>No Buy or Sell Tax 💸</li>
//         <li>Powered by a Viral Pooping Hippo 💩</li>
//         <li>Eco-Friendly Poop Dispensers ♻️</li>
//       </ul>
//     </div>
//   );
// };

// export default Features;
