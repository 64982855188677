import React, { useRef } from 'react';
import YouTube from 'react-youtube';
import './VideoPlayer.css';

const VideoPlayer = () => {
    const playerRef = useRef(null);

    const onPlayerReady = event => {
        const player = event.target;
        playerRef.current = player;
        player.seekTo(18); // Start the video at 18 seconds
    };

    const onPlayerStateChange = event => {
        const player = event.target;
        const currentTime = player.getCurrentTime();

        // If video reaches 25 seconds, restart from 18 seconds
        if (currentTime >= 25) {
            player.seekTo(18);
        }
    };

    const videoOptions = {
        playerVars: {
            autoplay: 1, // Auto-plays the video
            controls: 0, // Shows only basic video controls
            rel: 0, // Prevents showing related videos at the end
            modestbranding: 1, // Removes YouTube logo
            start: 18, // Starts at the desired second
            end: 25, // Ends at the desired second
            loop: 1,
        },
    };

    return (
        <div className='video-wrapper'>
            <YouTube
                videoId='PSKQ3ZNQ_O8' // Video ID of the YouTube video
                opts={videoOptions} // Options object to control the player
                onReady={onPlayerReady} // Handler for when the player is ready
                onStateChange={onPlayerStateChange} // Handler for state changes
                className='youtube-player'
            />
        </div>
    );
};

export default VideoPlayer;
